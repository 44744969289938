import {
  compose, split, replace, reduce,
} from 'ramda';
import { createClient } from 'contentful';

// Extract query parameters in to key value pairs.
export const roptoParams = compose(
  reduce(
    (acc, d) => {
      const res = split('=', d);
      return {
        ...acc,
        [res[0]]: res[1],
      };
    },
    {},
  ),
  split('&'),
  replace(/(^\?)/, ''),
);

export const setSessionStorage = (key, value) => {
  sessionStorage.setItem(key, JSON.stringify(value));
};

export const getSessionStorage = (key) => {
  if (typeof sessionStorage !== 'undefined') {
    return JSON.parse(sessionStorage.getItem(key));
  }

  return null;
};

export const clearSessionStorage = (key) => {
  if (typeof sessionStorage !== 'undefined') {
    return sessionStorage.removeItem(key);
  }

  return '';
};

export const getContentfulClient = (accessToken, space) => createClient({
  accessToken,
  space,
});

export const pushToDataLayer = (data) => {
  if (typeof window !== 'undefined') {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push(data);
  }
};

export default {
  roptoParams,
  setSessionStorage,
  getSessionStorage,
  clearSessionStorage,
  pushToDataLayer,
  getContentfulClient,
};
